import Vue from 'vue'
import VueRouter from 'vue-router'
import ScannerView from '../views/ScannerView.vue';
import SuccessView from '../views/SuccessView.vue';
import ErrorView from '../views/ErrorView.vue';
import VerifyFailedView from '../views/VerifyFailedView.vue';
import SelectView from '../views/SelectView.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'scanner',
    component: ScannerView,
    meta: {
      title: '扫描验证'
    }
  },
  {
    path:"/scanner",
    name:"scanner",
    component:ScannerView,
    meta:{
      title:'扫描验证'
    }
  },
  {
    path: '/select',
    component: SelectView,
    meta: {
      title: '扫描结果'
    }
  },
  {
    path: '/success',
    component: SuccessView,
    meta: {
      title: '验证成功'
    }
  },
  {
    path: '/error',
    component: ErrorView,
    meta: {
      title: '验证出现错误'
    }
  },
  {
    path: '/verifyFailed',
    component: VerifyFailedView,
    meta: {
      title: '验证失败'
    }
  }                   
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 如果路由元信息中有标题，则使用该标题
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router
