<template>
    <div class="success">
        <img style="width: 90vw;" src="../assets/logo.png" alt="">
        <img style="width: 80%;margin-left:10%;margin-bottom: 5vh;" src="../assets/t4.png" alt="">
        <div class="result-box">
            <div class="r-item">
                <p>特征判定①</p>
                <div class="r-text">
                    <div class="left">
                        <img src="../assets/s1.png" alt="">
                    </div>
                    <div class="right">
                        <span>AI算法验证通过！</span>
                    </div>
                </div>
            </div>
            <div class="r-item" v-if="hasTexture">
                <p>特征判定②</p>
                <div class="r-text">
                    <div class="left">
                        <img src="../assets/s1.png" alt="">
                    </div>
                    <div class="right">
                        <span>感官验证通过！</span>
                    </div>
                </div>
            </div>
            <div class="r-item" v-if="!hasTexture">
                <p>特征判定②</p>
                <div class="r-text">
                    <div class="left">
                        <img src="../assets/error.png" alt="">
                    </div>
                    <div class="right red">
                        <span>感官验证未通过！</span>
                    </div>
                </div>
            </div>
            <div class="r-item" v-if="hasTexture">
                <div class="r-text">
                    <div class="left">
                        <img src="../assets/s2.png" alt="">
                    </div>
                    <div class="right black">
                        <span>您所查询的是
                            <b>福建泉州利讯儿童用品有限公司</b>生产的正品
                            品质保证，请放心购买 </span>
                    </div>
                </div>
            </div>
            <div class="r-item" v-if="hasTexture">
                <div class="r-text">
                    <div class="left">
                        <img src="../assets/s3.png" alt="">
                    </div>
                    <div class="right black">
                        <p>此防伪码是第<b>{{ pageData.total_count }}</b>次查询</p>
                        <p>首次查询时间为：<b>{{ pageData.earliest_time }}</b></p>
                        <p v-if="false">本次查询的地点为：<b>{{ pageData.latest_location }}</b></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer" @click="returnScanner">
            <img src="../assets/return.png" alt="">
        </div>
    </div>
</template>

<script>
import verifyService from '../services/verifyService';
export default {
    name: 'SuccessView',
    data() {
        return {
            classService: new verifyService(),
            verifyedCount: 0,
            hasTexture: false,
            barcode: "",
            pageData: {
                "total_count": 0,
                "earliest_time": "",
                "latest_location": "",
                "latest_ip": ""
            }
        }
    },
    mounted() {
        this.verifyedCount = this.$route.query.vc;
        this.hasTexture = this.$route.query.hasTexture == "true";
        this.barcode = localStorage.getItem("barcode");
        this.loadData();
    },
    methods: {
        loadData() {
            this.classService.queryLog({
                barcode: this.barcode,
                action: "queryLog"
            }).then((res) => {
                console.log(res)
                this.pageData = res.data;
            })
        },
        returnScanner() {
            this.$router.push('/scanner');
        }
    }
}
</script>
<style scoped lang="scss">
.success {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    overflow: hidden;
    padding: 10px;
    background: linear-gradient(to bottom, #fcedf3, #ffffff);
    flex-direction: column;

    .img {
        width: 20%;

        img {
            width: 100%;
        }

        margin-bottom: 2em;
    }

    p {
        margin: 0px;
        font-size: 16px;
        font-weight: bold;
        margin-left: 10vw;
    }

    .select {
        display: flex;
        width: 75vw;
        margin-left: 10vw;
        flex: 1;
        align-items: center;

        .select-item {
            width: 100%;
            height: 30vh;
            background: white;
            border-radius: 25px;
            box-shadow: 0 0 20px #f5ecf8;
            display: flex;
            flex-direction: column;
            justify-items: center;
            justify-content: space-evenly;
            padding: 40px;
        }
    }

    .result-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        overflow: auto;
        height: 55vh;

        .r-item {
            width: 75vw;

            p {
                font-size: 16px;
                font-weight: bold;
                margin-left: 0;
                margin-bottom: 15px;
            }

            .r-text {
                border-top: 1px dashed #ccc;
                border-bottom: 1px dashed #ccc;
                display: flex;
                align-items: center;

                .left {
                    padding: 15px;
                    border-right: 1px dashed #ccc;

                    img {
                        width: 40px;
                    }
                }

                .right {
                    flex: 1;
                    padding: 0 20px;
                    font-size: 20px;
                    color: #009944;

                    &.black {
                        color: #000;
                        font-size: 12px;

                        p {
                            font-size: 12px;
                            font-weight: normal;
                            margin: 0;
                        }

                        b {
                            color: #009944;
                        }
                    }

                    &.red {
                        color: red;
                    }
                }
            }

        }
    }
}
</style>