<template>
    <div class="success">
        <img style="width: 90vw;" src="../assets/logo.png" alt="">
        <img style="width: 100%;margin-top: -5vh" src="../assets/t1.png" alt="">
        <p>请选择是否有特征 >>></p>
        <div class="select">
            <div class="select-item">
                <img src="../assets/ns.png" alt="" @click="success">
                <img src="../assets/ne.png" alt="" @click="error">
            </div>
        </div>
        <div class="footer" @click="returnScanner">
            <img src="../assets/return.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectView',
    data() {
        return {
            verifyedCount: 0
        }
    },
    mounted() {
        this.verifyedCount = this.$route.query.vc;
    },
    methods: {
        success() {
            this.$router.push('/success?vc=' + this.verifyedCount + '&hasTexture=true');
        },
        error() {
            this.$router.push('/success?vc=' + this.verifyedCount + '&hasTexture=false');
        },
        returnScanner() {
            this.$router.push('/scanner');
        }
    }
}
</script>
<style scoped lang="scss">
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.success {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: calc(100vh - 100px);
    width: 100vw;
    display: flex;
    padding: 10px;
    background: linear-gradient(to bottom, #fcedf3, #ffffff);
    flex-direction: column;

    .img {
        width: 20%;

        img {
            width: 100%;
        }

        margin-bottom: 2em;
    }

    p {
        margin: 0px;
        font-size: 16px;
        font-weight: bold;
        margin-left: 10vw;
    }

    .select {
        display: flex;
        width: 75vw;
        margin-left: 10vw;
        flex: 1;
        align-items: center;

        .select-item {
            width: 100%;
            height: 25vh;
            background: white;
            border-radius: 25px;
            box-shadow: 0 0 20px #f5ecf8;
            display: flex;
            flex-direction: column;
            justify-items: center;
            justify-content: space-evenly;
            padding: 10px 40px
        }
    }
}

</style>