<template>
    <div class="success">
        <img style="width: 90vw;" src="../assets/logo.png" alt="">
        <img style="width: 80%;margin-left:10%;margin-bottom: 5vh;" src="../assets/t4.png" alt="">
        <div class="result-box">
            <div class="r-item">
                <div class="r-text">
                    <div class="left">
                        <img src="../assets/error.png" alt="">
                    </div>
                    <div class="right red">
                        <span>{{ exceedCountLimit?"当前标签验证次数超过限制！":"验证失败！" }}</span>
                    </div>
                </div>
            </div>

        </div>
        <div class="footer" @click="returnScanner">
            <img src="../assets/return.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'VerifyFailedView',
    data() {
        return {
            exceedCountLimit: false
        }
    },
    mounted() {
        this.exceedCountLimit = this.$route.query.exceedCountLimit;
    },
    methods: {

    }
}
</script>
<style scoped lang="scss">
.success {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    overflow: hidden;
    padding: 10px;
    background: linear-gradient(to bottom, #fcedf3, #ffffff);
    flex-direction: column;

    .img {
        width: 20%;

        img {
            width: 100%;
        }

        margin-bottom: 2em;
    }

    p {
        margin: 0px;
        font-size: 16px;
        font-weight: bold;
        margin-left: 10vw;
    }

    .select {
        display: flex;
        width: 75vw;
        margin-left: 10vw;
        flex: 1;
        align-items: center;

        .select-item {
            width: 100%;
            height: 30vh;
            background: white;
            border-radius: 25px;
            box-shadow: 0 0 20px #f5ecf8;
            display: flex;
            flex-direction: column;
            justify-items: center;
            justify-content: space-evenly;
            padding: 40px;
        }
    }

    .result-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        overflow: auto;
        height: 55vh;

        .r-item {
            width: 75vw;

            p {
                font-size: 16px;
                font-weight: bold;
                margin-left: 0;
                margin-bottom: 15px;
            }

            .r-text {
                border-top: 1px dashed #ccc;
                border-bottom: 1px dashed #ccc;
                display: flex;
                align-items: center;

                .left {
                    padding: 15px;
                    border-right: 1px dashed #ccc;

                    img {
                        width: 40px;
                    }
                }

                .right {
                    flex: 1;
                    padding: 0 20px;
                    font-size: 20px;
                    color: #009944;

                    &.black {
                        color: #000;
                        font-size: 12px;

                        p {
                            font-size: 12px;
                            font-weight: normal;
                            margin: 0;
                        }

                        b {
                            color: #009944;
                        }
                    }

                    &.red {
                        color: red;
                    }
                }
            }

        }
    }
}
</style>