import ApiService from '../utils/apiService';
class VerifyService {
    constructor() {
        this.apiService = new ApiService();
        // if (process.env.NODE_ENV === 'development') {
        //     //开发环境
        //     this.apiService = new ApiService("https://xinma-verify-us-mdglhdbuoj.us-west-1.fcapp.run");
        // } else {
        //     //生产环境
        //     this.apiService = new ApiService("https://xinma-verify-us-mdglhdbuoj.us-west-1-vpc.fcapp.run");
        // }
        //内网访问
    }
    verifyCode(data) {
        return this.apiService.post2('/', data, false);
    }
    insertLog(data) {
        return this.apiService.post2('/', data, false);
    }
    queryLog(data) {
        return this.apiService.post2('/', data, false);
    }
}
export default VerifyService;